<template>
    <loader :status="loadStatus" :loading-message="$t('loadingEditor')" :error-message="$t('failedToLoadSong')">
        <editor v-if="song" :song="song"></editor>
        <template #error>
            <load-failure></load-failure>
        </template>
    </loader>
</template>

<script lang="ts">
import LoadFailure from "@components/load-failure/load-failure.vue";
import Loader from "@components/loader/loader.vue";
import Swal, { SweetAlertResult } from "sweetalert2";
import { defineComponent } from "vue";
import { LoadingStatus } from "../../../common/constants";
import type { Song } from "../../models/song/song";
import { getEditorStore } from "../../stores/editor.store";
import { getSongStore } from "../../stores/song.store";
import Editor from "./editor.vue";

export default defineComponent({
    beforeRouteEnter(to, from, next) {
        // Cannot use this.* here as it is beforeEnter
        const editorStore = getEditorStore();
        const songStore = getSongStore();

        if (!songStore.canAddSong) {
            next({ name: "dashboard" });
            return;
        }

        if (to.name === "create") {
            editorStore.createNewLocalSong();
        } else {
            const routeSongId: string = to.query.song as string;
            editorStore.loadSong(routeSongId);
        }
        next();
    },
    async beforeRouteLeave(to, from) {
        const hasChanged = this.$store.editor.hasSongChanged;
        if (hasChanged) {
            const result = await this.requestLeaveConfirmation();
            if (!result.isConfirmed) {
                return false;
            }
        }
        const editorStore = getEditorStore();
        editorStore.isNewSong = false;
    },
    components: {
        loader: Loader,
        editor: Editor,
        "load-failure": LoadFailure,
    },
    data() {
        return {
            windowUnloadBind: undefined as any,
        };
    },
    mounted() {
        this.windowUnloadBind = this.onWindowUnload.bind(this);
        window.addEventListener("beforeunload", this.windowUnloadBind);
    },
    unmounted() {
        window.removeEventListener("beforeunload", this.windowUnloadBind);
    },
    computed: {
        isNewSong(): boolean {
            return this.$store.editor.isNewSong;
        },
        song(): Song | undefined {
            return this.$store.editor.selectedSong as Song | undefined;
        },
        loadStatus(): LoadingStatus {
            return this.$store.editor.loadingStatus;
        },
    },
    methods: {
        async requestLeaveConfirmation(): Promise<SweetAlertResult> {
            return await Swal.fire({
                title: this.$t("actionLeaveEditorConfirmationTitle"),
                text: this.$t("actionLeaveEditorConfirmationText"),
                showCancelButton: true,
                cancelButtonText: this.$t("actionStay"),
                confirmButtonText: this.$t("actionLeave"),
                customClass: {
                    confirmButton: "btn-danger",
                },
                showClass: {
                    popup: "fade-in-animation",
                },
                hideClass: {
                    popup: "fade-out-animation",
                },
            });
        },
        onWindowUnload(event: BeforeUnloadEvent) {
            const hasChanged = this.$store.editor.hasSongChanged;
            if (!hasChanged) {
                return;
            }

            event.preventDefault();
            event.returnValue = true;
        },
    },
});
</script>
