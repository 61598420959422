import { SongStatus } from "@common/song-status";
import Joi from "joi";
import { defineStore } from "pinia";
import { LoadingStatus, MAX_SONGS, SyncStatus } from "../../common/constants";
import { api } from "../apis/api";
import { getEditorStore } from "../stores/editor.store";
import type { SongVisibility } from "../../common/song-metadata";
import type { Song } from "../models/song/song";

export const getSongStore = defineStore("song", {
    state() {
        return {
            songs: [] as Song[],
            loadStatus: LoadingStatus.UNINITIALIZED,
            editorStore: getEditorStore(),
            syncStatus: SyncStatus.NOT_SYNCED,
        };
    },
    getters: {
        getAll(state) {
            return (status: SongStatus = SongStatus.Default, filter: string | undefined = undefined): Song[] => {
                return (state.songs as Song[])
                    .filter(
                        (s) =>
                            s.status === status &&
                            (filter === undefined || s.title.toLowerCase().includes(filter.toLowerCase()))
                    )
                    .sort((a, b) => b.metadata.lastEditTime - a.metadata.lastEditTime);
            };
        },
        getById(state) {
            return (id: string): Song | undefined => {
                return (state.songs as Song[]).find((s) => s.id === id) as Song | undefined;
            };
        },
        loaded(state): boolean {
            return state.loadStatus === LoadingStatus.READY;
        },
        canAddSong(): boolean {
            return this.songs.length < MAX_SONGS;
        },
    },
    actions: {
        async update(song: Song): Promise<void> {
            try {
                await api.songs.setSong(song.id, song.toDTO());
                const updatedSong = song;

                const index = this.songs.findIndex((s) => s.id === song.id);
                if (index > -1) {
                    this.songs.splice(index, 1, updatedSong);
                } else {
                    this.songs.push(updatedSong);
                }

                // Updates editor status if needed
                if (this.editorStore.selectedSongId === song.id) {
                    this.editorStore.updateStatus(updatedSong.status);
                }
            } catch (error: unknown) {
                console.error(error);
                if (error instanceof Joi.ValidationError) {
                    this.$bus.emit("error", `Song could not be saved: ${error.message}`);
                } else {
                    this.$bus.emit("error", "Song could not be saved");
                }
            }

            await this.fetchSongs();
        },
        async updateVisibility(songId: string, visibility: SongVisibility): Promise<void> {
            await api.songs.updateVisibility(songId, visibility);
            await this.fetchSongs();
        },
        async delete(song: Song): Promise<void> {
            try {
                await api.songs.delete(song.id);

                const index = this.songs.findIndex((s) => s.id === song.id);
                if (index > -1) {
                    this.songs.splice(index, 1);
                }
            } catch (error: unknown) {
                console.error(error);
                this.$bus.emit("error", "Song could not be deleted");
            }

            await this.fetchSongs();
        },
        async fetchSongs(): Promise<void> {
            this.loadStatus =
                this.loadStatus === LoadingStatus.UNINITIALIZED ? LoadingStatus.LOADING : LoadingStatus.RELOADING;

            try {
                this.songs = await api.songs.getAll();
                this.loadStatus = LoadingStatus.READY;
            } catch (error: unknown) {
                console.error(error);
                this.$bus.emit("error", "Songs could not be loaded");
                this.loadStatus = LoadingStatus.ERROR;
            }
        },
    },
});

export type SongStore = ReturnType<typeof getSongStore>;
