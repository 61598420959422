<template>
    <nav v-hide-on-scroll="'#layout-content'" class="header navbar with-sidebar">
        <div class="container container-fluid">
            <div class="offcanvas-body">
                <button
                    class="btn btn-hover-gray-light bi bi-list d-xxl-none menu"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvas-sidebar"
                    aria-controls="offcanvas-sidebar"
                    :title="$t('openSideMenu')"
                    @keydown.enter="toggleOffcanvas"
                    tabindex="0"></button>

                <form class="d-flex mt-lg-0" role="search">
                    <input
                        id="search"
                        class="form-control"
                        type="search"
                        :placeholder="$t('searchSongs', { songStatus: songStatus.toLocaleLowerCase() })"
                        @input="onSearchChange"
                        @focus="onSearchFocus"
                        @blur="onSearchBlur" />
                </form>

                <div class="toolbar d-flex">
                    <button
                        class="btn btn-hover-gray-light bi bi-grid"
                        :class="{ 'bi-view-stacked': showGridMode, 'bi-grid': !showGridMode }"
                        @click="toggleViewMode"
                        @keydown.enter="toggleViewMode"
                        :title="$t('toggleViewMode')"
                        tabindex="0"></button>
                    <user-dropdown-menu :useLightStyle="true"></user-dropdown-menu>
                </div>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserDropdownMenu from "../user-dropdown-menu/user-dropdown-menu.vue";

export default defineComponent({
    props: {
        songStatus: {
            type: String,
            default: "",
        },
    },
    emits: {
        searchChange: (_value: string) => true,
        searchFocus: () => true,
        searchBlur: () => true,
    },
    components: {
        "user-dropdown-menu": UserDropdownMenu,
    },
    computed: {
        showGridMode(): boolean {
            return this.$store.config.showGridMode;
        },
        search(): HTMLElement {
            return this.$refs.search as HTMLElement;
        },
    },
    methods: {
        toggleViewMode(): void {
            this.$store.config.toggleViewMode();
        },
        toggleOffcanvas(event: KeyboardEvent): void {
            const button = event.target as HTMLButtonElement;
            button.click();
        },
        onSearchChange(event: Event): void {
            const target = <HTMLInputElement>event.target;
            this.$emit("searchChange", target.value);
        },
        onSearchFocus(): void {
            this.$emit("searchFocus");
        },
        onSearchBlur(): void {
            this.$emit("searchBlur");
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    padding: 8px;

    .offcanvas-body {
        position: relative;
        overflow: visible;

        form {
            .form-control {
                border-radius: 22px;
                padding-left: 40px;
                padding-right: 95px;
                height: 46px;
                line-height: 46px;
            }
        }

        .menu,
        .toolbar {
            position: absolute;
            top: 50%;
            z-index: 10;
            transform: translateY(-50%);
        }

        .menu {
            left: 1px;
            border-top-left-radius: 22px;
            border-bottom-left-radius: 22px;
        }

        .toolbar {
            right: 1px;
        }
    }

    @media (min-width: 1400px) {
        background-color: var(--background-color-secondary);
        border-bottom: solid 1px var(--gray-300);

        .offcanvas-body {
            form {
                .form-control {
                    padding-left: 20px;
                }
            }
        }
    }
}
</style>
