<template>
    <page-wrapper :showCreateSong="false">
        <settings-header></settings-header>

        <main class="settings">
            <div class="container">
                <form class="row g-3" @click.prevent>
                    <!-- <div class="form-group col-12" v-if="canUpdatePassword">
                        <button @click="updatePassword" class="btn">Update Password</button>
                    </div> -->

                    <div class="form-group col-12">
                        <select class="form-select form-select-lg" v-model="selectedLanguage" @change="changeLanguage">
                            <option value="en">English</option>
                            <option value="es">Español</option>
                        </select>
                    </div>

                    <div class="form-group col-12" v-if="userLoggedIn">
                        <button @click="logout" class="btn btn-gray">{{ $t("logOut") }}</button>
                    </div>
                    <div class="form-group col-12" v-if="!userLoggedIn">
                        <button @click="login" class="btn btn-gray">{{ $t("logIn") }}</button>
                    </div>

                    <hr class="border-2 border-top border-danger" />

                    <div class="form-group col-12" v-if="userLoggedIn">
                        <button @click="deleteAccount" class="btn btn-danger">{{ $t("deleteAccount") }}</button>
                    </div>
                    <div class="form-group col-12" v-if="!userLoggedIn">
                        <button @click="clearData" class="btn btn-danger">{{ $t("clearData") }}</button>
                    </div>
                    <div class="form-group col-12">
                        <version></version>
                    </div>
                </form>
            </div>
        </main>
    </page-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { api } from "../../apis/api";
import SettingsHeader from "./settings-header/settings-header.vue";
import Swal from "sweetalert2";
import PageWrapper from "@components/page-wrapper/page-wrapper.vue";
import Version from "@components/version/version.vue";
import { FirebaseError } from "@firebase/app";

export default defineComponent({
    name: "Settings",
    components: {
        "page-wrapper": PageWrapper,
        "settings-header": SettingsHeader,
        version: Version,
    },
    data() {
        return {
            selectedLanguage: this.$i18n.locale,
        };
    },
    computed: {
        canUpdatePassword(): boolean {
            return this.$store.user.authProvider === "email";
        },

        userLoggedIn(): boolean {
            return this.$store.user.loggedIn;
        },
        needsReload(): boolean {
            return this.$store.user.needReload;
        },
    },
    methods: {
        onNavigateBack(): void {
            this.$router.push({
                name: "dashboard",
            });
        },
        changeLanguage() {
            this.$i18n.locale = this.selectedLanguage;
            api.localStorage.set("userLocale", this.selectedLanguage);
        },
        async deleteAccount(): Promise<void> {
            const result = await Swal.fire({
                title: this.$t("actionDeleteAccountConfirmationTitle"),
                text: this.$t("actionCannotBeUndone"),
                showCancelButton: true,
                cancelButtonText: this.$t("actionCancel"),
                confirmButtonText: this.$t("actionDelete"),
                customClass: {
                    confirmButton: "btn-danger",
                },
                icon: "warning",
            });
            if (result.isConfirmed) {
                try {
                    if (!api.auth.isIOSApp) {
                        await api.auth.reauthenticate();
                    }
                    await api.auth.deleteAccount();
                    await this.$store.user.logout();
                    localStorage.clear();
                    await this.$router.push({ name: "dashboard" });
                } catch (error) {
                    // Delete Account may fail if the user didn't authenticate
                    // recently, ask for reauthentication before trying again

                    if (error instanceof FirebaseError) {
                        switch (error.code) {
                            // TODO: properly handle these errors
                            case "auth/popup-closed-by-user":
                                this.$bus.emit(
                                    "error",
                                    `Error Deleting Account (popup closed): Reauthenticate to delete the account`
                                );
                                api.log.error(error);
                                return;
                        }
                    }

                    this.$bus.emit("error", `Error Deleting Account: ${error}`);
                    throw error;
                }
            }
        },
        // async updatePassword(): Promise<void> {
        //     await Swal.fire({
        //         title: "Update Password",
        //         input: "password",
        //         inputPlaceholder: "Enter your new password",
        //         showCancelButton: true,
        //         confirmButtonText: "Update Password",
        //         showLoaderOnConfirm: true,
        //         allowOutsideClick: () => !Swal.isLoading(),
        //         preConfirm(pwd: string) {
        //             return api.auth.updatePassword(pwd).catch((error) => {
        //                 Swal.showValidationMessage(error);
        //             });
        //         },
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Swal.fire({
        //                 title: `Password Updated`,
        //             });
        //         }
        //     });
        // },
        async logout(): Promise<void> {
            await this.$store.user.logout();
            await this.$router.push({ name: "dashboard" });
        },
        async login(): Promise<void> {
            await this.$router.push({
                name: "signin",
            });
        },
        async clearData(): Promise<void> {
            const result = await Swal.fire({
                title: this.$t("actionClearDataConfirmationTitle"),
                text: this.$t("actionCannotBeUndone"),
                showCancelButton: true,
                cancelButtonText: this.$t("actionCancel"),
                confirmButtonText: this.$t("actionClearData"),
                customClass: {
                    confirmButton: "btn-danger",
                },
                icon: "warning",
            });
            if (result.isConfirmed) {
                await api.syncApi.onLogout();
                localStorage.clear();
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.settings {
    padding-bottom: 90px;
    padding-top: 90px;

    .container {
        background-color: var(--background-color-secondary);
        align-items: stretch;
        flex-direction: column;
        padding: 30px;
        border-radius: 15px;

        form {
            width: 60%;
            margin: 0 auto;

            .btn,
            select {
                width: 100%;
            }

            select {
                padding-left: 36px;
                text-align: center;
            }
        }
    }
}
</style>
